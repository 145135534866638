import axios from "axios";
import { environment } from "../../environments/environment";

export class LoginService {
  async login(email, password) {
    return await axios.post(
      `${environment.endpoints.base_invoice_api_url}/auth/token`,
      {
        email,
        password,
      },
      {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    );
  }
}

export default new LoginService();
