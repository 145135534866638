import styled, {css} from 'styled-components';

export const Container = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 240px;
  height: 56px;
  background: #0063CF;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #F8F9FA;

  ${props => props.full && css`
    width: 100%;
  `};

  ${props => props.link && css`
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #0063CF;
    background-color: #FFF;
    font-weight: bold;
    padding: 0;
    cursor: pointer;
    justify-content: left;
  `};

  @media(max-width: 575px) {
    margin: 24px 0;
    width: 100%;

    ${props => props.link && css`
      width: 100%;
      text-align: center;
      justify-content: center;
  `};
  }
`
