import React from "react";
import "./style.css";
import {ButtonPrimary} from "../../shared/components/UI/ButtonPrimary";

export default function TransactionFailed({ onBackPress }) {
  return (
    <div className="container-fail">
        <div className="box">
            <div className="text-center">
                <img alt="" id="fail" /><br/>
                <span className="titleFail">Pagamento não autorizado!</span>
                <span className="subtitleFail">Verifique se todos os dados estão corretos<br id="breakLarge"/> e tente novamente.</span>
                <ButtonPrimary className="m-0-auto mt-24" onClick={onBackPress} text="Voltar" icon="arrow_back"/>
            </div>
        </div>
    </div>
  );
}
