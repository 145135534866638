import React from "react";
import "./style.css";
import medicinaeInvoice from "../../shared/medicinaeInvoice";
import {useHistory} from "react-router-dom";
import {ButtonPrimary} from "../../shared/components/UI/ButtonPrimary";

export default function RegisterSuccess() {

    let history = useHistory();
    const goToPayment = () => {
        history.push({
            pathname: "/payment-logged-in",
            search: `?invoiceId=${medicinaeInvoice.id}`,
        });
    }

    return (
        <div className="container-success">
            <div className="box">
                <div className="text-center">
                    <img alt="" id="success" /><br/>
                    <span className="titleSuccess">Cadastro realizado com sucesso!</span><br/>
                    <ButtonPrimary className="m-0-auto mt-24" onClick={goToPayment} text="Seguir para pagamento"/>
                </div>
            </div>
        </div>

    );
}
