/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import useForm from "../../shared/hooks/useForm";
import validate from "../../shared/validations/registerFormValidationRules";
import RegisterService from "./service";
import LocalStorageService from "../../shared/services/local-storage";
import * as Sentry from "@sentry/react";
import medicinaeInvoice from "../../shared/medicinaeInvoice";
import {Input} from "../../shared/components/UI/Input";
import formatValue from '../../shared/formatation/registerFormFormatationRules';
import "../../App.css";
import "./style.css";
import * as Snowplow from '../../shared/services/snowplow';
import Container from "../../shared/components/Container";
import BasicLoadingScreen from "../BasicLoadingScreen";
import RegisterSuccess from "../RegisterSuccess";
import {ButtonPrimary} from "../../shared/components/UI/ButtonPrimary";
import {Toast} from "../../shared/components/UI/Toast";
import {Bold, Text} from "../PaymentLoggedIn/style";

export default function Register() {
    Snowplow.formTracking();

    const {values, handleChange, onSubmit, patchValue} = useForm({
        handleSubmit,
        validate,
        initialValues: {
            birthDate: '',
            line2: '',
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
            postalCode: '',
            city: '',
            state: '',
            number: '',
            street: '',
            phoneNumber: '',
            cpf: '',
        },
        formatValue
    });

    const [findingCep, setFindingCep] = useState({
        found: false,
        loading: false,
        error: false,
    });

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [addressVisible, setAddressVisible] = useState(false);
    const [errors, setErrors] = useState({});
    const [disablePersonalData, setDisablePersonalData] = useState(true);
    const [disableAddressData, setDisableAddressData] = useState(true);
    const [validateAddress, setValidateAddress] = useState(false);

    async function handleSubmit() {
        const err = validate(values, true) ?? {};
        setErrors(err);
        if (Object.keys(err).length > 0) {
            return;
        }

        setLoading(true);
        try {
            const {
                birthDate,
                line2,
                name,
                email,
                password,
                password_confirmation,
                postalCode,
                city,
                state,
                number,
                street,
                phoneNumber,
                cpf,
            } = values;

            const patchData = {
                birthDate: birthDate.split("/").reverse().join("-"),
                cpf: cpf.replace(/\D/g, ""),
                email,
                password,
                password_confirmation,
                phoneNumber: phoneNumber.replace(' ', '').trim(),
                name,
                address: {
                    postal_code: postalCode.replace('-', ''),
                    city,
                    state,
                    number,
                    street,
                    line2: line2 || "",
                    country_code: "BR",
                },
            };

            const response = await RegisterService.registerUser(patchData, medicinaeInvoice.id);

            LocalStorageService.setObject("accessToken", response.data.accessToken);
            LocalStorageService.setObject("refreshToken", response.data.refreshToken);
            LocalStorageService.setObject("user", response.data.data);

            setSuccess(true);
        } catch (e) {
            console.error("error trying to patch user", e);
            Sentry.captureException(e);

            if (checkIfExistsUser(e)) {
                setErrorMsg('Usuário já existe!');
            } else {
                e?.response?.data?.messages
                    ? setErrorMsg(e.response.data.messages[0])
                    : setErrorMsg("Ocorreu um erro, tente novamente mais tarde.");
            }

            setLoading(false);
        }
    }

    const checkIfExistsUser = (error) => {
        return !!error?.response?.data?.developer_details;

    }

    const handleCepChange = async (event) => {
        handleChange(event);
        const cep = event.target.value.replace(/[^\w\s]/gi, "");

        let value = cep.substr(0, 5);
        if (cep.length > 5) value += "-" + cep.substr(5, 3);

        patchValue([event.target.name], value);

        if (cep.length === 8) {
            try {
                setFindingCep({found: false, loading: true});
                const address = await RegisterService.getAddressFromCep(cep);

                if (address.data.erro) {
                    setFindingCep({found: true, loading: false, error: true});
                    setErrors({...errors, postalCode: 'CEP inválido!'})
                } else {
                    setFindingCep({
                        found: true,
                        loading: false,
                        error: !!address.data.erro,
                    });

                    if (errors.postalCode) {
                        const errAux = {...errors};
                        delete errAux.postalCode;
                        setErrors(errAux);
                    }

                    patchValue("street", address.data.logradouro);
                    patchValue("state", address.data.uf);
                    patchValue("city", address.data.localidade);
                }
            } catch (e) {
                setErrorMsg('Erro ao buscar informações do CEP.');
                console.error("Error finding CEP information", e);
            }
        }
    };

    const dsbPersonalData = () => {
        if (values["name"] !== "" &&
            values["email"] !== "" &&
            values["password"] !== "" &&
            values["password_confirmation"] !== "" &&
            values["cpf"] !== "" &&
            values["birthDate"] !== "" &&
            values["phoneNumber"] !== "" &&
            values["postalCode"] !== "") {
            setDisablePersonalData(false);
        } else {
            setDisablePersonalData(true);
        }
    }

    const dsbAddressData = () => {
        if (values["street"] !== "" &&
            values["number"] !== "" &&
            values["city"] !== "" &&
            values["state"] !== "") {
            setDisableAddressData(false);
        } else {
            setDisableAddressData(true);
        }
    }

    const personalData = () => {
        return (
            <>
                <Input
                    name="name"
                    id="name"
                    value={values["name"] || ""}
                    onChange={handleChange}
                    type="text"
                    placeholder="Digite seu nome"
                    header="Nome"
                    errors={errors}
                />
                <Input
                    name="email"
                    id="email"
                    value={values["email"] || ""}
                    onChange={handleChange}
                    type="email"
                    placeholder="Digite seu e-mail"
                    header="E-mail"
                    errors={errors}
                />
                <div className="row">
                    <div className="col-sm-6">
                        <Input
                            name="password"
                            id="password"
                            value={values["password"] || ""}
                            onChange={handleChange}
                            placeholder="******"
                            header="Senha"
                            showpasswordvisibility="true"
                            errors={errors}
                        />
                    </div>
                    <div className="col-sm-6">
                        <Input
                            name="password_confirmation"
                            id="password_confirmation"
                            value={values["password_confirmation"] || ""}
                            onChange={handleChange}
                            placeholder="******"
                            header="Confirme a senha"
                            errors={errors}
                            showpasswordvisibility="true"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <Input
                            name="cpf"
                            id="cpf"
                            value={values["cpf"] || ""}
                            onChange={handleChange}
                            type="text"
                            placeholder="Digite seu CPF"
                            header="CPF"
                            errors={errors}
                        />
                    </div>
                    <div className="col-sm-6">
                        <Input
                            name="birthDate"
                            id="birthDate"
                            value={values["birthDate"] || ""}
                            onChange={handleChange}
                            type="text"
                            placeholder="00/00/0000"
                            header="Data de nascimento"
                            errors={errors}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <Input
                            name="phoneNumber"
                            id="phoneNumber"
                            value={values["phoneNumber"] || ""}
                            onChange={handleChange}
                            type="text"
                            placeholder="Digite seu número"
                            header="Telefone ou celular"
                            errors={errors}
                        />
                    </div>
                    <div className="col-sm-6">
                        <Input
                            name="postalCode"
                            id="postalCode"
                            value={values["postalCode"] || ""}
                            onChange={handleCepChange}
                            type="text"
                            placeholder="00000-000"
                            header="CEP"
                            errors={errors}
                        />
                    </div>
                </div>

                <ButtonPrimary
                    text="Avançar"
                    icon="arrow_forward"
                    full
                    type="button"
                    onClick={handleGoToAddressData}
                    className="mt-24"
                    disabled={disablePersonalData}
                />
            </>
        )
    }

    const addressData = () => {
        return (
            <>
                {findingCep.loading && !findingCep.found && !findingCep.error && (
                    <div className="text-center">
                        <div className="spinner-border text-primary mt-5" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                )}

                {!findingCep.loading &&
                    findingCep.found &&
                    !findingCep.error &&
                    values["postalCode"].length === 9 && (
                        <>
                            <Input
                                name="street"
                                id="street"
                                value={values["street"] || ""}
                                onChange={handleChange}
                                type="text"
                                placeholder="Digite o logradouro"
                                errors={errors}
                                header="Endereço"
                            />
                            <div className="row">
                                <div className="col-sm-6">
                                    <Input
                                        name="number"
                                        id="number"
                                        value={values["number"] || ""}
                                        onChange={handleChange}
                                        type="text"
                                        placeholder="Digite o número"
                                        errors={errors}
                                        header="Número"
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <Input
                                        name="line2"
                                        id="line2"
                                        value={values["line2"] || ""}
                                        onChange={handleChange}
                                        type="text"
                                        placeholder="Digite o complemento"
                                        errors={errors}
                                        header="Complemento"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <Input
                                        name="state"
                                        id="state"
                                        value={values["state"] || ""}
                                        onChange={handleChange}
                                        type="text"
                                        placeholder="Digite o estado"
                                        maxLength={2}
                                        errors={errors}
                                        header="Estado"
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <Input
                                        name="city"
                                        id="city"
                                        value={values["city"] || ""}
                                        onChange={handleChange}
                                        type="text"
                                        placeholder="Digite a cidade"
                                        errors={errors}
                                        header="Cidade"
                                    />
                                </div>
                            </div>

                            <div className="row rowButtons mt-24">
                                <div className="col-sm-6 colAlignMiddle">
                                    <ButtonPrimary onClick={() => goBack()} text="Voltar" icon="arrow_back" link type="button"/>
                                </div>
                                <div className="col-sm-6">
                                    <ButtonPrimary
                                        text="Finalizar cadastro"
                                        className="mt-24 w-180"
                                        disabled={disableAddressData}
                                    />
                                </div>
                            </div>
                            <div className="smallButtons">
                                <ButtonPrimary
                                    text="Finalizar cadastro"
                                    className="mt-24"
                                    disabled={disableAddressData}/>
                                <ButtonPrimary onClick={() => goBack()} text="Voltar" icon="arrow_back" link type="button"/>
                            </div>


                        </>
                    )}
            </>
        )
    }

    const handleGoToAddressData = () => {
        const err = validate(values) ?? {};
        setErrors(err);
        if (Object.keys(err).length === 0) {
            setAddressVisible(true);
            setValidateAddress(true);
        }
    }


    const goBack = () => {
        setAddressVisible(false);
        setValidateAddress(false);
    }

    useEffect(() => {
        if (validateAddress) {
            dsbAddressData();
        } else {
            dsbPersonalData();
        }
    }, [dsbAddressData, dsbPersonalData, validateAddress, values]);

    if (success) return <RegisterSuccess/>;
    if (loading) return <BasicLoadingScreen/>;

    return (
        <>
            {(errorMsg !== null) && (
                <Toast error msg={errorMsg}></Toast>
            )}
            <Container partial={!addressVisible}>
                <Text>
                    <p><Bold>Crie sua conta!</Bold> Para iniciarmos seu cadastro, nos conte um pouco sobre você.
                    </p>
                </Text>
                <form onSubmit={(event) => onSubmit(event)}>
                    {!addressVisible && personalData()}
                    {addressVisible && addressData()}
                </form>
            </Container>
        </>
    );
}
