import React, {memo} from "react";
import {Container} from "./styles";

export const ButtonPrimary = memo(({form, ...props}) => {

    const {icon, text} = props;

    return (
        <>
            <Container {...props}>
                {icon && (
                    <span className='material-icons'>{icon}</span>
                )}
                {text}
            </Container>
        </>
    );
});
