import styled from "styled-components";

export const Header = styled.h4`
  color: ${props => props?.color ?? "#263238"};
  font-size: 16px;
  line-height: 24px;
  font-weight: ${props => props?.fontWeight ?? "700"};
  font-family: Roboto;
  margin-bottom: 16px;

  &.soon {
    margin-top: 16px;
  }
`;