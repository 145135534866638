import { checkIfCpfIsValid, checkIfDateIsValid } from "../../utils/validate";

export default function validate(values, validateAddressData = false) {
  let errors = {};

  if (!values.name) {
    errors.name = 'O campo Nome é obrigatório.';
  }

  if (!values.cpf) {
    errors.cpf = 'O campo CPF é obrigatório.';
  }
  else {
    const isValid = checkIfCpfIsValid(values.cpf);
    if (!isValid) errors.cpf = "Número de CPF inválido.";
  }

  if (!values.email) {
    errors.email = 'O campo E-mail é obrigatório.';
  }
  else {
    // eslint-disable-next-line no-useless-escape
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const isValid = regex.test(values.email);
    if (!isValid) errors.email = "E-mail inválido!";
  }

  if (!values.password) {
    errors.password = 'O campo Senha é obrigatório.';
  }
  else if (values.password.length < 6) {
    errors.password = 'O campo Senha deve conter no mínimo 6 caracteres.';
  }

  if (!values.password_confirmation) {
    errors.password_confirmation = 'O campo Confirmação de Senha é obrigatório.';
  }

  if (values.password && values.password_confirmation && values.password !== values.password_confirmation) {
    errors.password_confirmation = 'A confirmação para o campo Senha não coincide.';
  }

  if (!values.birthDate) {
    errors.birthDate = 'O campo Data de nascimento é obrigatório.';
  }
  else {
    const isValid = checkIfDateIsValid(values.birthDate);
    if (!isValid) errors.birthDate = 'Data inválida.';
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = 'O campo Telefone é obrigatório.';
  }

  if (!values.postalCode) {
    errors.postalCode = 'O campo CEP é obrigatório.';
  }

  if (values.postalCode && !errors.postalCode && validateAddressData) {
    if (!values.street) {
      errors.street = 'O campo Logradouro é obrigatório.';
    }

    if (!values.number) {
      errors.number = 'O campo Número é obrigatório.';
    }

    if (!values.city) {
      errors.city = 'O campo Cidade é obrigatório.';
    }

    if (!values.state) {
      errors.state = 'O campo Estado é obrigatório.';
    }
  }

  return errors;
};
