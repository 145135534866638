import React from 'react';
import Icon from '../../UI/Icon';
import * as S from "./styles";

function PaymentMethodItem({ to, display, iconName, inactive = false }) {
  return (
    <S.Container
      to={to ?? ""}
      inactive={inactive.toString()}
    >
      <span>
        <Icon
          iconName={iconName}
          inactive={inactive.toString()}
        />
        <S.Display
          inactive={inactive.toString()}
        >{display}</S.Display>
      </span>
      {!inactive && <Icon
        iconName="chevron_right"
        inactive={inactive.toString()}
      />}
    </S.Container>
  );
}

export default PaymentMethodItem