const checkIfCpfIsValid = (cpf) => {
  cpf = cpf.replace(/\D+/g, "");
  if (
    cpf.length !== 11 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  )
    return false;
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) return false;
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  return rev === parseInt(cpf.charAt(10));
};

const checkIfDateIsValid = (value) => {
  const date_regex = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
  return date_regex.test(value);
};

const checkIfYearIsExpired = (value) => {
  if (!value) return true;

  let year = Number(value.slice(value.length - 2));
  year = year < 2000 ? 2000 + year : year;
  let currentYear = new Date().getFullYear();
  let month = Number(value.slice(0, 2));
  let currentMonth = new Date().getMonth() + 1;

  return year < currentYear || (year === currentYear && month < currentMonth);
};

export { checkIfCpfIsValid, checkIfDateIsValid, checkIfYearIsExpired };
