import * as Sentry from "@sentry/react";
import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router-dom";
import { environment } from "../../../environments/environment";
import InvoiceService from "../../../service";
import { Input } from "../../../shared/components/UI/Input";
import formatValue from "../../../shared/formatation/formatationRules";
import useForm from "../../../shared/hooks/useForm";
import medicinaeInvoice from "../../../shared/medicinaeInvoice";
import validate from "../../../shared/validations/paymentScreenValidationRules";
import { formatCurrency } from "../../../utils/formatter";
import { ButtonPrimary } from "../UI/ButtonPrimary";
import "./style.css";

const _paq = window._paq || [];

function PaymentCard(props) {
    const initialValues = {
        ccname: "",
        cardnumber: "",
        cvc: "",
        ccexp: "",
        cpf: "",
        installments: 1,
        value: 0
    };
    const [token, setToken] = useState('');
    const {handleChange, onSubmit, values, errors} = useForm({
        initialValues,
        handleSubmit,
        validate,
        formatValue,
    });

    const validateEmptyValues = (values) => {
        return values["ccname"] === "" ||
               values["cpf"] === "" ||
               values["cardnumber"] === "" ||
               values["ccexp"] === "" ||
               values["cvc"] === "" ||
               values["installments"] === ""
    }

    const history = useHistory();

    async function handleSubmit() {
        const {totalValue} = medicinaeInvoice.installmentOptions.find((option) => option.installments === Number(values.installments));


        const formData = {
            card_number: values.cardnumber.replace(/\D/g, ""),
            card_holder_name: values.ccname,
            cpf: values.cpf.replace(/\D/g, ""),
            expiration_month: Number(values.ccexp.split("/")[0]),
            expiration_year: Number(`20${values.ccexp.split("/")[1]}`),
            security_code: values.cvc.replace(/\D/g, ""),
            installments: Number(values.installments),
            value: String(totalValue),
            token: 'token_dev'
        };
        if(environment.name !== 'dev' && (environment.captcha_card_number.indexOf(formData.card_number) === -1) ){
            formData['token'] = captchaRef.current.getValue();
        }

        _paq.push([
            "trackEvent",
            "Pagamento iniciado",
            `Link ${medicinaeInvoice.id}`,
            totalValue,
        ]);

        if (Object.values(formData).some((field) => !field)) return;

        let transactionId;

        try {
            props.setLoading(true);
            const result = await InvoiceService.handlePaymentWithoutSavingCard(
                medicinaeInvoice.id,
                formData
            );

            transactionId = result.data.id
            props.onTransactionResponse({type: "success", transactionId});
            _paq.push([
                "trackEvent",
                "Pagamento concluído com sucessso",
                `Link ${medicinaeInvoice.id}`,
                totalValue,
            ]);
        } catch (e) {
            props.setLoading(false);
            props.onTransactionResponse({type: "fail"});
            console.error("Error trying to proccess transaction", e);
            Sentry.captureException(e);
            _paq.push([
                "trackEvent",
                "Pagamento concluído com erro",
                `Link ${medicinaeInvoice.id}`,
                (e && e.response && e.response.data && e.response.data.error) ||
                "Erro não identificado",
                totalValue,
            ]);
        } finally {
            props.setLoading(false);

        }
    }

    const changeSelect = (e) => {
        handleChange(e);
    }

    const renderInstallments = (installments) => {
        let opts = [];
        // eslint-disable-next-line
        installments.map((item) => {
            let optionValue = "";
            if (item.installments === 1) {
                optionValue = `À vista ${formatCurrency(item.totalValue)}`;
            } else {
                if (item.installmentValue < environment.minimum_installment_value) {
                    // eslint-disable-next-line
                    return;
                }

                optionValue = `${item.installments}x ${item.interestFree ? "sem juros" : "com juros"} ${formatCurrency(item.installmentValue)} (${formatCurrency(item.totalValue)})`;
            }

            opts.push(<option value={item.installments} key={item.installments}>{optionValue}</option>);
        });
        return opts;
    };

    const goBack = () => {
        history.push('/payment');
    }

    const tokenOrCardBypass = () => {
        if(environment.captcha_card_number.indexOf(values.cardnumber.replace(/\D/g, "")) > -1){
            return false;
        }
        return (token === '')
    }

    const captchaRef = useRef(null)
    return (
        <>
            <form className="payment-card-form" onSubmit={(event) => onSubmit(event)}>
                <div className="row">
                    <div className="col-md-12">
                        <Input
                            name="ccname"
                            value={values["ccname"] || ""}
                            onChange={handleChange}
                            id="ccname"
                            autoComplete="cc-name"
                            placeholder="Digite o nome do titular"
                            maxLength={26}
                            errors={errors}
                            header="Nome do titular do cartão"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Input
                            name="cpf"
                            value={values["cpf"] || ""}
                            onChange={handleChange}
                            type="text"
                            id="cpf"
                            autoComplete="cpf"
                            placeholder="Digite o número do CPF"
                            maxLength={14}
                            errors={errors}
                            header="CPF do titular do cartão"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Input
                            name="cardnumber"
                            value={values["cardnumber"] || ""}
                            onChange={handleChange}
                            type="text"
                            id="cardnumber"
                            autoComplete="cc-number"
                            placeholder="Digite o número do cartão"
                            maxLength={19}
                            errors={errors}
                            header="Número do cartão"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Input
                            name="ccexp"
                            value={values["ccexp"] || ""}
                            onChange={handleChange}
                            id="ccexp"
                            autoComplete="cc-exp"
                            type="text"
                            placeholder="MM/AA"
                            maxLength={5}
                            errors={errors}
                            header="Vencimento do cartão"
                        />
                    </div>
                    <div className="col-md-6">
                        <Input
                            name="cvc"
                            value={values["cvc"] || ""}
                            onChange={handleChange}
                            id="cvc"
                            autoComplete="cc-csc"
                            type="text"
                            placeholder="CVV"
                            maxLength={4}
                            errors={errors}
                            header="Código de segurança"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className={`box-field ${values["installments"] === "" ? "input-error" : ""}`}>
                            <span className="header header-valid">Número de parcelas</span>
                            <select
                                name="installments"
                                id="installments"
                                className="form-select form-transaction"
                                value={values["installments"] || ""}
                                onChange={changeSelect}>
                                {renderInstallments(medicinaeInvoice.installmentOptions)}
                            </select>
                        </div>
                    </div>
                </div>

                {environment.name !== 'dev' && <>
                    <div style={{marginTop: 8, marginBottom: 8}}>
                        <ReCAPTCHA
                            sitekey={environment.captcha_site_key}
                            ref={captchaRef}
                            onChange={setToken}
                        />
                    </div>
                </>}

                <div className="row btn-actions">
                    <div className="col-sm-5">
                        <ButtonPrimary onClick={() => goBack()} text="Voltar" icon="arrow_back" link type="button"
                                       className="btn-previous"/>
                    </div>
                    <div className="col-sm-7">
                        <button className="btn btn-lg pay" type="submit"
                                disabled={tokenOrCardBypass() || validateEmptyValues(values)}>
                            Realizar pagamento
                        </button>
                    </div>
                </div>
                <div className="row btn-actions mobile">
                    <div className="col-sm-7">
                        <button className="btn btn-lg pay" type="submit"
                                disabled={tokenOrCardBypass() || validateEmptyValues(values)}>
                            Realizar pagamento
                        </button>
                    </div>
                    <div className="col-sm-5">
                        <ButtonPrimary onClick={() => goBack()} text="Voltar" icon="arrow_back" link type="button"
                                       className="btn-previous"/>
                    </div>
                </div>
            </form>
        </>
    );
}

export default PaymentCard;