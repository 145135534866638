import axios from "axios";
import { environment } from "../../environments/environment";

export class ResetPasswordService {
  async createNewPassword(id, formData) {
    return await axios.put(
      `${environment.endpoints.base_invoice_api_url}/passwordResetRequest/${id}`,
      formData,
      {
        headers: {},
      }
    );
  }
}

export default new ResetPasswordService();
