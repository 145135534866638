export const environment = {
  production: false,
  name: "dev",
  title: "Pague com iClinic Pay - Dev",
  endpoints: {
    base_invoice_api_url: "https://pagar.dev.iclinicpay.com.br",
    base_api_url: "https://api.dev.iclinicpay.com.br",
    receipt_url: "https://receipt.dev.iclinicpay.com.br",
  },
  snowplow_collector_url: "",
  snowplow_app_id: "iclinicpay_invoice",
  time_to_check: 10000,
  minimum_installment_value: 5,
  captcha_site_key: "6LeGODAkAAAAAJBbo2u_EsAN4a-MFuKbxKi1yIlp",
  captcha_card_number: ["4444444444444444", "4111111111111111"],
};
