import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled(Link)`
  align-items: center;
  border: ${props => props.inactive === "true" ? "0px solid #228BF0":"1px solid #228BF0" };
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 16px 18px;
  text-decoration: none;

  &:hover {
    background-color: ${props => props => props.inactive === "true" ? "transparent" : "#D9EBFC" };
    cursor: ${props => props => props.inactive === "true" ? "not-allowed" : "pointer"};
  }

  & + a {
    margin: 8px 0;
  }


  > span {
    align-items: center;
    display: flex;
  }
`;

export const Display = styled.span`
  color: ${props => props.inactive === "true" ? "#90A4AE":"#455A64"};
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-left: 13px;
`;