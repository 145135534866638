const copyTextOrShare = async (text) => {
  if ('share' in navigator) {
    return await navigator.share(text);
  } else if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return await document.execCommand('copy', true, text);
  }
}

export { copyTextOrShare }