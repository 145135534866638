
export class LocalStorageService {

  set(key, value) {
    window.localStorage[key] = value;
  }

  get(key, defaultValue = null) {
    return window.localStorage[key] || defaultValue;
  }

  setObject(key, value) {
    this.set(key, JSON.stringify(value));
  }

  getObject(key) {
    return JSON.parse(window.localStorage.getItem(key) || "{}");
  }

  remove(key) {
    window.localStorage.removeItem(key);
  }

  getUser() {
    let user = this.getObject("user");
    if (!user) return;
    return user;
  }

  getOrganizacaoId() {
    let user = this.getObject("user");
    if (!user) return;
    return user.organizacoes[0].id;
  }

  getRole() {
    let user = this.getObject("user");
    if (!user) return;
    return user.role_name;
  }

  getUserRoleLevel() {
    let user = this.getObject("user");
    if (!user) return;
    return user.role_level;
  }

  getUserPermissions() {
    let user = this.getObject("user");
    if (!user) return;
    return user.permissoes;
  }

  checkAuthorize() {
    let accessToken = this.getObject("accessToken");
    if (Object.keys(accessToken).length === 0) {
        return false;
    }

    return true;
  }

  logout() {
    this.remove("accessToken");
    this.remove("refreshToken");
    this.remove("user");
  }
}

export default new LocalStorageService();
