export const medicinaeInvoice = window.medicinaeInvoice || {
  id: "11111",
  sellerName: "Alexandre Vicente da Silva",
  value: 1.32,
  description: "teste proxy",
  installmentOptions: [
    { installments: 1, interestFree: true, installmentValue: 1, totalValue: 1 },
    {
      installments: 2,
      interestFree: false,
      installmentValue: 0.51,
      totalValue: 1.01,
    },
    {
      installments: 3,
      interestFree: false,
      installmentValue: 0.34,
      totalValue: 1.01,
    },
  ],
  elligibleForVisaCashback: false,
};

export default medicinaeInvoice;
