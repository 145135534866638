import React from "react";
import InfoError from "../../../../images/info-error.svg";
import * as S from "./styles.js";

function PixBadRequestScreen(props) {
  return (
    <S.Container>
      <img src={InfoError} alt="info-error" />
      <S.Title>Ops! Algo deu errado.</S.Title>
      <S.SubTitle>Desculpe, tivemos alguns problemas técnicos durante sua última operação.</S.SubTitle>
      <S.Button className="btn btn-lg pay" onClick={props.onTryAgain}>Tentar novamente</S.Button>
    </S.Container>
  );
}

export default PixBadRequestScreen;