import styled from 'styled-components';

export const Info = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 50px 50px 0 50px;

    @media (max-width:680px) {
        margin: 40px 24px 0 24px;
    }

    @media print {
        display: none
    }

    button{
        border: none;
        background-color: transparent;

        img {
            margin-right: 10px;
        }

        a {
            text-decoration: none;
            line-height: 16px;
            color: #455A64;
            letter-spacing: 0.3px;
            vertical-align: middle;
        }

        span{
            line-height: 16px;
            color: #455A64;
            letter-spacing: 0.3px;
            vertical-align: middle;
        }
    }
`

export const Container = styled.div`
    margin-bottom: -42px;

    @media (max-width:680px) {
        margin-bottom: -34px;
    }
`
export const User = styled.div`
    img {
        margin-right: 20px;
    }

    span {
        font-weight: 500;
        font-size: 12px;
        line-height: 32px;
        letter-spacing: 1px;
        color: #455A64;
    }
`
