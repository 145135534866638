import React from 'react';
import { useHistory } from "react-router-dom";
import * as infoUser from './styles';
import LocalStorageService from "../../services/local-storage";
import UserIcon from '../../../images/user-icon.svg';
import ExitIcon from '../../../images/exit-icon.svg';

export default function InfoUser(props){

    const userName = LocalStorageService.getUser().name.split(" ");
    let userFirstAndtLastName = `${userName[0]} ${userName[userName.length-1]}`;
    userFirstAndtLastName = userFirstAndtLastName.toUpperCase();

    const history = useHistory();

    const logout = () => {
        LocalStorageService.remove('user');
        history.push({
            pathname: `/login`
        });
    }

    return(
        <infoUser.Container>
            <infoUser.Info>
                <infoUser.User>
                    <img src={UserIcon} alt='User Icon'/>
                    <span>OLÁ, {userFirstAndtLastName}</span>
                </infoUser.User>
                <button
                    type='button'
                    onClick={logout}
                >
                    <img src={ExitIcon} alt='User Icon'/>
                    <span>Sair</span>
                </button>
            </infoUser.Info>
            <main>{props.children}</main>
        </infoUser.Container>
    )
}