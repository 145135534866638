import React from "react";
import "./style.css";
import {ButtonPrimary} from "../../shared/components/UI/ButtonPrimary";
import { environment } from "../../environments/environment";
import { useHistory } from "react-router-dom";

export default function TransactionSuccess(props) {
    const history = useHistory();

    const handleClick = () => {
        window.open(`${environment.endpoints.receipt_url}/${props.transactionId}`, "_black");
        setTimeout(() => {
            history.push("/")
        }, 1000);
    }

    return (
        <div className="container-success">
            <div className="box">
                <div className="text-center">
                    <img alt="" id="success" /><br/>
                    <span className="titleSuccess">Pagamento realizado com sucesso!</span><br/>
                    <ButtonPrimary className="m-0-auto mt-24" onClick={handleClick} text="Ver comprovante"/>
                </div>
            </div>
        </div>

    );
}
