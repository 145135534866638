export default function FormatationRules(name, value) {
    const formatCardName = (value) => {
        const strSChar = "áàãâäéèêëíìîïóòõôöúùûüçÁÀÃÂÄÉÈÊËÍÌÎÏÓÒÕÖÔÚÙÛÜÇ";
        const strNoSChars = "aaaaaeeeeiiiiooooouuuucAAAAAEEEEIIIIOOOOOUUUUC";
        let newStr = "";
        for (var i = 0; i < value.length; i++) {
            if (strSChar.indexOf(value.charAt(i)) !== -1) {
                newStr += strNoSChars.substr(strSChar.search(value.substr(i, 1)), 1);
            } else {
                newStr += value.substr(i, 1);
            }
        }
        return newStr.replace(/[^a-zA-Z ]/g, '');
    }

    const formatCardNumber = (value) => {
        const digits = value.replace(/\D/g, "");
        let result = digits.substr(0, 4);
        if (digits.length > 4) result += " " + digits.substr(4, 4);
        if (digits.length > 8) result += " " + digits.substr(8, 4);
        if (digits.length > 12) result += " " + digits.substr(12, 4);
        return result;
    }

    const formatCCExp = (value) => {
        if (value.length > 5) return;
        if (
            value.length !== 0 &&
            value[0] !== "0" &&
            value[0] !== "1"
        )
            return '';
        if (value[0] === "1" && Number(value[1]) > 2)
            return value[0];
        if (value[2]) {
            let year = Number(value[2]);
            let currentYear = Number(new Date().getFullYear().toString().substring(2, 3));
            if (year < currentYear)
                return value[0] + value[1] + '/'
        }
        const digits = value.replace(/\D/g, "");
        let result = digits.substr(0, 2);
        if (digits.length > 2) result += "/" + digits.substr(2);
        return result;
    }

    const formatCpf = (value) => {
        const digits = value.replace(/\D/g, "");
        let result = digits.substr(0, 3);
        if (digits.length > 3) result += "." + digits.substr(3, 3);
        if (digits.length > 6) result += "." + digits.substr(6, 3);
        if (digits.length > 9) result += "-" + digits.substr(9, 2);
        return result;
    }

    const formatCnpj = (value) => {
        let result = value.substr(0, 2);
        if (value.length > 2) result += "." + value.substr(2, 3);
        if (value.length > 5) result += "." + value.substr(5, 3);
        if (value.length > 8) result += "/" + value.substr(8, 4);
        if (value.length > 12) result += "-" + value.substr(12, 2);
        return result;
    }

    const formatCvc = (value) =>{
        return value.replace(/\D/g, "");
    }

    const adicionaZero = (value) =>{
        if (value <= 9) 
            return "0" + value;
        else
            return value; 
    }

    const formatDate = (value) =>{
        let newDate = new Date(value);
        let result = (adicionaZero(newDate.getDate().toString()) + "/" + (adicionaZero(newDate.getMonth()+1).toString()) + "/" + newDate.getFullYear());
        return result
    }

    const formatHour = (value) =>{
        let newDate = new Date(value);
        let result = (adicionaZero(newDate.getHours().toString()) + ":" + (adicionaZero(newDate.getMinutes()).toString()) + ":" + (adicionaZero(newDate.getSeconds())));
        return result
    }

    const formatAddressReceipt = (value) =>{
        let result = `${value.logradouro} ${value.numero} - ${value.estado}`
        return result
    }

    const ruleName = {
        ccname: formatCardName,
        cardnumber: formatCardNumber,
        cvc: formatCvc,
        ccexp: formatCCExp,
        cpf: formatCpf,
        cnpj: formatCnpj,
        date: formatDate,
        hour: formatHour,
        addressReceipt: formatAddressReceipt
    }

    if (!ruleName[name]) {
        return value;
    }
    return ruleName[name](value);
}
