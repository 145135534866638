import styled from 'styled-components';

export const GoToPayment = styled.p`
  font-family: 'Roboto',serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  color: #455A64;
  cursor: pointer;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
`
