import React from "react";
import { useRouteMatch } from "react-router-dom";
import PaymentMethodItem from "./PaymentMethodItem";
import * as S from "./styles";

function PaymentMethod() {
  let { url } = useRouteMatch();

  return (
    <>
      <S.Header>Selecionar forma de pagamento:</S.Header>
      <PaymentMethodItem
        iconName="credit_card"
        display="Cartão de crédito"
        to={`${url}/card`}
      />
      <PaymentMethodItem
        iconName="pix"
        display="PIX"
        to={`${url}/pix`}
      />
      <S.Header fontWeight="400" color="#607D8B" className="soon">Em breve...</S.Header>
      <PaymentMethodItem
        iconName="view_column"
        display="Boleto"
        inactive
      />
      <PaymentMethodItem
        iconName="autorenew"
        display="Transferência bancária"
        inactive
      />
      <PaymentMethodItem
        iconName="pix"
        display="Cartão de débito"
        inactive
      />
    </>);
}

export default PaymentMethod;