import axios from "axios";
import * as Sentry from "@sentry/react";
import { environment } from "../../environments/environment";

export class RegisterService {
  async getAddressFromCep(cep) {
    try {
      return await axios.get(`https://viacep.com.br/ws/${cep}/json/`, {
        headers: {},
      });
    } catch (e) {
      Sentry.captureException(e);
      return {};
    }
  }

  async registerUser(data, id) {
    return await axios.post(
      `${environment.endpoints.base_api_url}/api/v2/payment/register/${id}`,
      data,
      {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    );
  }
}

export default new RegisterService();
