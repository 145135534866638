/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { formatCurrency } from "../../utils/formatter";
import ProcessingScreen from "../ProcessingScreen";
import TransactionSuccess from "../TransactionSuccess";
import TransactionFail from "../TransactionFail";
import medicinaeInvoice from "../../shared/medicinaeInvoice";
import { useHistory } from "react-router-dom";
import * as Snowplow from '../../shared/services/snowplow';
import TransformValueByFieldName from '../../shared/transform/transformValueByFieldName';
import LocalStorageService from "../../shared/services/local-storage";
import IClinicPay from "../../images/iclinicpay.svg";
import Divider from "../../images/divider.svg";
import "./style.css";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import PaymentMethod from "../../shared/components/PaymentMethod";
import PaymentCard from "../../shared/components/PaymentCard";
import PaymentPix from "../../shared/components/PaymentPix";
import PageNotFound from "../../shared/components/PageNotFound";

function PaymentScreen(props) {

  let { path } = useRouteMatch();

  useEffect(() => {
    LocalStorageService.remove('accessToken');
    LocalStorageService.remove('refreshToken');
    LocalStorageService.remove('user');
  }, [])

  Snowplow.formTracking({
    fields: {
      denylist: ['cvc'],
      transform: function (value, ele) {
        return TransformValueByFieldName(value, ele);
      }
    },
  });

  const [loading, setLoading] = useState(false);
  const [transactionResponse, setTransactionResponse] = useState({});
  const [urlPath, setUrlPath] = useState("/payment");

  const history = useHistory();
  const pageNotFound = Object.keys(medicinaeInvoice).length === 0;

  const goToRegister = () => {
    history.push("/register");
  }

  const goToLogin = () => {
    history.push("/login");
  }

  const handlePreviousPage = () => {
    history.push("/payment");
  }

  useEffect(() => {
    if (urlPath !== window.location.pathname) {
      if (window.location.pathname === "/payment") {
        history.push("/");
      }
      setUrlPath(window.location.pathname);
    }
  }, [props]);

  if (loading) return <ProcessingScreen />;
  if (transactionResponse.type === "success")
    return <TransactionSuccess transactionId={transactionResponse.transactionId} />;
  if (transactionResponse.type === "fail")
    return <TransactionFail onBackPress={() => setTransactionResponse({})} />;

  return (
    <div>
      <div className="container-pay">
        <div className="row container-pay-row">
          <div className="col-md-12 center">
            <div id="header">
              <div className="row header-row">
                <div className="col-md-8 pd-l-0">
                  <img src={IClinicPay} alt="" id="logo" />
                  <img src={Divider} alt="" id="divider" />
                  <img alt="" id="link" />
                </div>
                {!pageNotFound && <div className="col-md-4 pd-r-0">
                  <div id="buttons">
                    <button className="btn btn-outline-secondary btn-outline" type="button"
                      onClick={goToRegister}>Cadastrar
                    </button>
                    <button className="btn btn-outline-secondary btn-login" type="button"
                      onClick={goToLogin}>
                      <span className="material-icons">person</span>
                      Login
                    </button>
                  </div>
                </div>}
              </div>
            </div>
            {pageNotFound ?
              <PageNotFound />
              : (
                <div className="row">
                  <div className="col-md-5">
                    <div id="card-info">
                      <div id="area-name">
                        <span className="card-info-title">Cobrança</span>
                        <span>{medicinaeInvoice.sellerName}</span>
                      </div>
                      <div id="area-description">
                        <span className="card-info-title">Referência</span>
                        <span>{medicinaeInvoice.description}</span>
                      </div>
                      <span className="amount">{formatCurrency(medicinaeInvoice.value)}</span>
                    </div>
                    <div id="card-tips">
                      <span className="card-tips-title">DICAS</span>
                      <span>
                        Acesse <strong>“Cadastrar”</strong> e crie uma conta com a gente para realizar seus pagamentos em um só clique. Se já possui cadastro é só clicar em <strong>“Login”</strong>!
                      </span>
                    </div>
                  </div>
                  <div className='col-md-7'>
                    <div id="card-form">
                      <Switch>
                        <Route exact path={path}>
                          <PaymentMethod />
                        </Route>
                        <Route path={`${path}/card`}>
                          <PaymentCard setLoading={setLoading} onTransactionResponse={setTransactionResponse} />
                        </Route>
                        <Route path={`${path}/pix`}>
                          <PaymentPix onTransactionResponse={setTransactionResponse} onPreviousPage={handlePreviousPage} />
                        </Route>
                      </Switch>
                    </div>
                  </div>
                </div>
              )}
          </div>
          <div className="p-0">
            <div className="p-0">
              <div id="card-tips-sm">
                <span className="card-tips-title">DICAS</span>
                <span>
                  Acesse <strong>“Cadastrar”</strong> e crie uma conta com a gente para realizar seus pagamentos em um só clique. Se já possui cadastro é só clicar em <strong>“Login”</strong>!
                </span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default PaymentScreen;
