import React, { useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import Register from "./components/Register";
import ProcessingScreen from "./components/ProcessingScreen";
import PaymentLoggedIn from "./components/PaymentLoggedIn";
import ResetPassword from "./components/ResetPassword";
import PaymentScreen from "./components/PaymentScreen";
import * as Snowplow from "./shared/services/snowplow";

import { useLocation } from "react-router-dom";

function Routes() {
  let location = useLocation();

  useEffect(() => {
    Snowplow.trackPageView();
  }, [location]);

  return (
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/register">
        <Register />
      </Route>
      <Route path="/processing">
        <ProcessingScreen />
      </Route>
      <Route path="/payment-logged-in">
        <PaymentLoggedIn />
      </Route>
      <Route path="/payment">
        <PaymentScreen />
      </Route>
      <Route path="/reset-password">
        <ResetPassword />
      </Route>
      <Redirect from="/" to="/payment" />
    </Switch>
  );
}

export default Routes;
