import React from "react";
import "./style.css";

export default function BasicLoadingScreen() {
  return (
      <div className="container-processing">
        <div className="box">
          <div className="text-center">
              <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
              </div>
          </div>
        </div>
      </div>
  );
}
