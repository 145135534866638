import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    place-items: center;
    max-width: 590px;
    padding: 30px 100px 36px 100px;
    margin: 50px;

    border-top: 4px solid #0063CF;
    box-shadow: 0px 4px 14px rgba(0, 99, 207, 0.06), 0px 6px 16px rgba(0, 99, 207, 0.02), 0px 6px 24px rgba(0, 99, 207, 0.04);

    img {
        width: 200px;
        margin-bottom: 30px;
    }

    main {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    @media (max-width: 680px) {
        margin: 40px 24px 24px 24px;
        padding-left: 24px;
        padding-right: 24px;
    }
`
export const ContainerPartial = styled.div`
  display: grid;
  place-items: center;
  max-width: 590px;
  padding: 30px 100px 36px 100px;
  margin: 50px;
  position: relative;

  border-top: 4px solid #0063CF;
  box-shadow: 0px 4px 14px rgba(0, 99, 207, 0.06), 0px 6px 16px rgba(0, 99, 207, 0.02), 0px 6px 24px rgba(0, 99, 207, 0.04);

  img {
    width: 200px;
    margin-bottom: 30px;
  }

  main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 680px) {
    margin: 40px 24px 24px 24px;
    padding-left: 24px;
    padding-right: 24px;
  }

  &:after {
    position: absolute;
    left: 50%;
    right: -1px;
    top: -6px;
    border-top: 10px solid #FFF;
    content: '';
  }
`
