export default function TransformValueByFieldName(value, ele) {

  const transformCardNumber = () => {
    const arrSplit = value.split(' ');
    return `${arrSplit[0]} **** **** ${arrSplit[3]}`;
  }

  const transformCpf = () => {
    if (value.length === 14) {
      const arrSplit = value.split('.');
      const rest = arrSplit[2].split('-');
      return `${arrSplit[0]}.***.***-${rest[1]}`;
    }

    return value;
  }

  const methods = {
    cardnumber: transformCardNumber,
    cpf: transformCpf,
  };

  if (Object.keys(methods).includes(ele.name)) {
    return methods[ele.name]();
  }

  return value;
};