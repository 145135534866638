import React from "react";
import "./style.css";
import {useHistory} from 'react-router-dom';
import {ButtonPrimary} from "../../../../shared/components/UI/ButtonPrimary";


export default function LinkSent() {
    let history = useHistory();

    const goToLogin = () => {
        history.push("/login");
    }

    return (
        <div className="container-success">
            <div className="box">
                <div className="text-center">
                    <img alt="" id="success"/><br/>
                    <span className="titleSuccess">Abra o link enviado por e-mail para criar sua nova senha!</span><br/>
                        <ButtonPrimary className="m-0-auto mt-24" onClick={() => goToLogin()} icon="arrow_back" text="Voltar"/>
                </div>
            </div>
        </div>
    );
}
