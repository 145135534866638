import React from 'react';
import { Container as Box, ContainerPartial } from './styles';
import IclinicPayAzul from '../../../images/iclinicpay-azul.svg';

export default function Container(props){
    return(
        <>
            {props.partial && (
                <ContainerPartial>
                    <img src={IclinicPayAzul} alt='logo iClinic Pay'/>
                    <main>
                        {props.children}
                    </main>
                </ContainerPartial>
            )}
            {!props.partial && (
                <Box>
                    <img src={IclinicPayAzul} alt='logo iClinic Pay'/>
                    <main>
                        {props.children}
                    </main>
                </Box>
            )}
        </>
    )
}
