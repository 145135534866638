import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  height: 100%;
  position: relative;

  .container-processing {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;

    .box {
      height: 100%;
    }
  }
`;

export const QrCodeArea = styled.div`
  margin: 0 auto;
  opacity: ${props => props.expired ? "0.1" : "1"};
  position: relative;
  transition: ease-in-out 0.2s opacity;
`;

export const Refresh = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  label, span {
    color: #607D8B;
    cursor: inherit;
  }

  label {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-top: 6px;
  }
`;

export const Info = styled.div`
  text-align: justify;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
`;

export const QrCodeCopyAndPaste = styled.div`
  border: 2px dashed #228BF0;
  border-radius: 8px;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  margin: 20px 0;

  input {
    font-size: 18px;
    color: #607D8B !important;
    outline: none;
    border: none;
    width: 100%;
  }

  span.material-icons.primary  {
    color: #228BF0;
    font-size: 20px;
    cursor: pointer;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 420px) {
    flex-direction: column;
    position: relative;
  }
`;

export const BtnBack = styled(Actions)`
  color: #0063CF;
  cursor: pointer;
  height: 56px;
  justify-content: start;
  position: relative;

  span.material-icons.primary  {
    color: #0063CF;
  }

  @media (max-width: 420px) {
    flex-direction: row;
  }
`;

export const BtnShared = styled(Actions)`
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  color: #228BF0;

  border: 1px solid #228BF0;
  border-radius: 4px;
  height: 56px;
  width: 248px;
  padding: 16px;
  cursor: pointer;
  position: relative;


  span.material-icons.primary  {
    color: #228BF0;
  }

  @media (max-width: 420px) {
    flex-direction: row;
  }
`;

export const ContainerButtonsShared = styled.div`
  height: 56px;
  width: 100%;
  background: #fff;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: end;
  transform: translateY(0%);
  padding-left: 20px;

  button.email > svg > Circle {
    fill: #03a9f4;
  }

  button + button {
    margin-left: 10px;
  }
`;