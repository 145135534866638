import {checkIfCpfIsValid, checkIfYearIsExpired} from "../../utils/validate";

export default function validate(values) {
    let errors = {};

    if (!values.ccname) {
        errors.ccname = 'O campo Nome do Titular é obrigatório.';
    }

    if (!values.cardnumber) {
        errors.cardnumber = 'O campo Número do Cartão é obrigatório.';
    } else {
        if (values.cardnumber.length < 16) errors.cardnumber = "Número do cartão deve ter mais que 13 dígitos";
    }

    if (!values.cvc) {
        errors.cvc = 'O campo Código de Segurança é obrigatório.';
    }

    if (!values.ccexp) {
        errors.ccexp = 'O campo Vencimento é obrigatório.';
    } else {
        if (values.ccexp.length < 5) errors.ccexp = "Vencimento incompleto.";
        const isExpired = checkIfYearIsExpired(values.ccexp);
        if (isExpired) errors.ccexp = "Vencimento expirado.";
    }

    if (!values.cpf) {
        errors.cpf = 'O campo Cpf é obrigatório.';
    } else {
        const isValid = checkIfCpfIsValid(values.cpf);
        if (!isValid) errors.cpf = "Número de CPF inválido.";
    }

    return errors;
}
