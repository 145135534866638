import React from 'react';
import './styles.css';
import NotFound from "../../../images/notfound.svg";

function PageNotFound(props) {
  return <div className='container'>
    <img src={NotFound} alt="página não encontrada" />
    <div className='title'>Página de pagamento não encontrada!</div>
    <div class="sub-title">Verifique o link utilizado ou entre em contato com a clínica.</div>
  </div>
}
export default PageNotFound;