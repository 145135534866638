export default function validate(values) {
  let errors = {};
  if (!values.email) {
    errors.email = "O campo E-mail é obrigatório.";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "E-mail inválido.";
  }

  if (!values.password) {
    errors.password = "O campo Senha é obrigatório.";
  } else if (values.password.length < 6) {
    errors.password = "O campo Senha deve conter no mínimo 6 caracteres.";
  }

  if (!values.password_confirmation) {
    errors.password_confirmation = "O campo Confirme nova senha é obrigatório.";
  } else if (values.password !== values.password_confirmation) {
    errors.password_confirmation =
      "A confirmação para o campo Senha não coincide.";
  }

  return errors;
}
