import axios from "axios";
import { environment } from "../../environments/environment";

export class ForgotPasswordService {
  async requestReset(email) {
    return await axios.post(
      `${environment.endpoints.base_invoice_api_url}/passwordResetRequest`,
      {
        email,
      },
      {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    );
  }
}

export default new ForgotPasswordService();
