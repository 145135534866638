import React, {memo} from "react";
import "./styles.css";

export const Switch = memo(({form, ...props}) => {

    return (
        <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" role="switch" {...props}/>
            <label className="form-check-label" htmlFor={props.id}>{props.label}</label>
        </div>
    );
});
