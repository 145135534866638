const formatCurrency = (value) => {
  let formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  });
  return formatter.format(value);
};

const formatCardName = (value) => {
  const strSChar = "áàãâäéèêëíìîïóòõôöúùûüçÁÀÃÂÄÉÈÊËÍÌÎÏÓÒÕÖÔÚÙÛÜÇ";
  const strNoSChars = "aaaaaeeeeiiiiooooouuuucAAAAAEEEEIIIIOOOOOUUUUC";
  let newStr = "";
  for (var i = 0; i < value.length; i++) {
    if (strSChar.indexOf(value.charAt(i)) !== -1) {
      newStr += strNoSChars.substr(strSChar.search(value.substr(i, 1)), 1);
    } else {
      newStr += value.substr(i, 1);
    }
  }
  return newStr.replace(/[^a-zA-Z ]/g, "");
};

const formatCardNumber = (value) => {
  const digits = value.replace(/\D/g, "");
  let result = digits.substr(0, 4);
  if (digits.length > 4) result += " " + digits.substr(4, 4);
  if (digits.length > 8) result += " " + digits.substr(8, 4);
  if (digits.length > 12) result += " " + digits.substr(12, 4);
  return result;
};

const formatCCExp = (value) => {
  if (value.length > 5) return;
  if (value.length !== 0 && value[0] !== "0" && value[0] !== "1") return "";
  if (value[0] === "1" && Number(value[1]) > 2) return value[0];
  if (value[2]) {
    let year = Number(value[2]);
    let currentYear = Number(
      new Date().getFullYear().toString().substring(2, 3)
    );
    if (year < currentYear) return value[0] + value[1] + "/";
  }
  if (value[3]) {
    let year = Number(value[3]);
    let currentYear = Number(
      new Date().getFullYear().toString().substring(2, 3)
    );
    if (year < currentYear) return value[0] + value[1] + "/";
  }
  if (value[4]) {
    let year = Number(value[3] + value[4]);
    let currentYear = Number(new Date().getFullYear().toString().substring(2));
    if (year < currentYear) return value[0] + value[1] + "/" + value[3];
  }
  const digits = value.replace(/\D/g, "");
  let result = digits.substr(0, 2);
  if (digits.length > 2) result += "/" + digits.substr(2);
  return result;
};

const formatCpf = (value) => {
  const digits = value.replace(/\D/g, "");
  let result = digits.substr(0, 3);
  if (digits.length > 3) result += "." + digits.substr(3, 3);
  if (digits.length > 6) result += "." + digits.substr(6, 3);
  if (digits.length > 9) result += "-" + digits.substr(9, 2);
  return result;
};

const formatCvc = (value) => {
  return value.replace(/\D/g, "");
};

const formatPhoneNumber = (value) => {
  const digits = value.replace(/\D/g, "");
  if (digits.length === 0) {
    return "";
  }
  if (digits.length <= 10) {
    let result = `(${digits.substr(0, 2)})`;
    if (digits.length > 2) result += " " + digits.substr(2, 4);
    if (digits.length > 5) result += "-" + digits.substr(6, 4);
    return result;
  } else {
    let result = `(${digits.substr(0, 2)})`;
    if (digits.length > 2) result += " " + digits.substr(2, 5);
    if (digits.length > 5) result += "-" + digits.substr(7, 4);
    return result;
  }
};

const formatDate = (value) => {
  const digits = value.replace(/\D/g, "");
  let result = digits.substr(0, 2);
  if (digits.length > 2) result += "/" + digits.substr(2, 2);
  if (digits.length > 4) result += "/" + digits.substr(4, 4);
  return result;
};

export {
  formatCurrency,
  formatCardName,
  formatCardNumber,
  formatCCExp,
  formatCpf,
  formatCvc,
  formatPhoneNumber,
  formatDate
};
