import React, {useEffect} from "react";
import "./toast.scss";
import {Slide, toast, ToastContainer} from "react-toastify";

export const Toast = ({...props}) => {

    useEffect(() => {
        if (props.success) {
            success(props.msg);
        }

        if (props.error) {
            error(props.msg);
        }
    }, [props.error, props.msg, props.success]);

    const success = (msg) => toast.success(msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
        transition: Slide
    });

    const error = (msg) => toast.error(msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
        transition: Slide
    });


    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
            />
        </>
    );
};
