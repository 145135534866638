import React from "react";
import "./styles.css";

function Icon({ iconName, inactive=false, ...props }) {

  return (
    <span
      {...props}
      className={`material-icons md-18 primary ${inactive==="true"?"inactive":""}`}>
      {iconName}
    </span>
  );
}

export default Icon;