/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

const useForm = ({ handleSubmit, validate, formatValue, initialValues }) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (typeof initialValues === 'function') {
      throw new Error('The initialValues not defined');
    }
  }, []);

  const onSubmit = (event) => {
    if (event) event.preventDefault();
    const err = validate ? validate(values):{};
    if (Object.keys(err).length > 0) {
      setErrors(err);
    }
    else if (Object.keys(errors).length === 0) {
      setSubmitting(true);
      handleSubmit();
      setTimeout(() => {
        setSubmitting(false);
      }, 500);
    }
  };

  const handleChange = (event) => {
    event.persist();
    let currentValue = "";

    if (formatValue) {
      currentValue = formatValue(event.target.name, event.target.value);
    } else {
      currentValue = event.target.value;
    }

    if (Object.keys(errors).length > 0) {
      setErrors(validate({ ...values, [event.target.name]: currentValue }));
    }

    setValues((values) => ({ ...values, [event.target.name]: currentValue }));
  };

  const patchValue = (field, value) => {
    setValues((values) => ({ ...values, [field]: value }));
  };

  return {
    handleChange,
    onSubmit,
    patchValue,
    values,
    errors,
    submitting
  };
};

export default useForm;
