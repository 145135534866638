import { formatCardName, formatCpf, formatPhoneNumber, formatDate } from '../../utils/formatter';
export default function RegisterFormFormatationRules(name, value) { 
  const ruleName = {
    name       : formatCardName,
    cpf        : formatCpf,
    phoneNumber: formatPhoneNumber,
    birthDate  : formatDate
}

if (!ruleName[name]) {
    return value;
}
return ruleName[name](value);
}