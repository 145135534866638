/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import * as S from "./styles";

const REFRESH_QRCODE = "Gerar novo QRCode";

function Ellapsed(props) {
  const [display, setDisplay] = useState("");
  const [status, setStatus] = useState("finish");

  const countdown = (date) => {
    let formatedDate = date.replace(/-/g, "/");
    let expirationDate = new Date(formatedDate);
    expirationDate.setHours(expirationDate.getHours() - 3);

    const now = new Date();
    let calc = expirationDate - now;

    if (calc > 900) {
      let minutes = parseInt(calc / 60000, 10);
      let seconds = parseInt((calc % 60000) / 1000, 10);

      if (minutes === 0 && seconds <= 30 && status !== "ending") {
        setStatus("ending");
      }

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      return `${minutes}:${seconds}`;
    }

    return REFRESH_QRCODE;
  }


  useEffect(() => {
    if (props.expirationDate) {
      const result = countdown(props.expirationDate);
      setDisplay(result);

      if (result === REFRESH_QRCODE) {
        setStatus("finish");
        props.onExpired();
      }
      else {
        setStatus("started");
      }
    }
  }, [props.expirationDate]);

  useEffect(() => {
    if (status !== "finish") {
      const interval = setInterval(() => {
        const result = countdown(props.expirationDate);
        setDisplay(result);

        if (result === REFRESH_QRCODE) {
          setStatus("finish");
          props.onExpired();
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [status]);

  return (
    <S.Container status={status}>
      {display}
    </S.Container>
  );
}

export default Ellapsed;