import {checkIfYearIsExpired} from "../../utils/validate";

export default function validate(values) {
    let errors = {};
    if (values.credit_card_id === 'new') {
        if (!values.ccname) {
            errors.ccname = 'O campo Nome do titular é obrigatório.';
        }

        if (!values.cardnumber) {
            errors.cardnumber = 'O campo Número do Cartão é obrigatório.';
        } else {
            if (values.cardnumber.length < 16) errors.cardnumber = "Número do cartão deve ter mais que 13 dígitos";
        }

        if (!values.ccexp) {
            errors.ccexp = 'O campo Vencimento é obrigatório.';
        } else {
            if (values.ccexp.length < 5) errors.ccexp = "Vencimento incompleto.";
            const isExpired = checkIfYearIsExpired(values.ccexp);
            if (isExpired) errors.ccexp = "Vencimento expirado.";
        }

        if (!values.cvc) {
            errors.cvc = 'O campo Código de Segurança é obrigatório.';
        }
    }

    return errors;
};
